import React from "react";
import anime_1 from "../../images/banner/anime1.png";
import anime_2 from "../../images/banner/anime2.png";
import anime_3 from "../../images/banner/anime3.png";
import anime_4 from "../../images/banner/anime4.png";
import anime_5 from "../../images/banner/anime5.png";
import Button from "../Button/Button";
import BannerRight from "./BannerRight";
const Banner = () => {
	return (
		<section className="banner-section">
			<div className="d-lg-none">
				<BannerRight />
			</div>
			<div className="container">
				<div className="banner-wrapper">
					<div className="banner-left">
						<h5 className="text--base subtitle">
							Decentralized Conference
						</h5>
						<h1 className="title">
							San Francisco{" "}
							<strong className="text--base">Blockchain Week</strong>
						</h1>
						<p>
							SF Blockchain Week is where blockchain startups, enterprise
							companies, academics, developers, and investors from around
							the world come together to define the future of blockchain
							and cryptocurrencies.
						</p>
						<Button href="#bottom" text="Sign Up" />
					</div>
					<div className="banner-right d-none d-lg-block">
						<BannerRight />
						{/* Animation Element Images */}
						<img
							src={anime_1}
							className="anime-elem elem-1"
							alt="banner"
						/>
						<img
							src={anime_2}
							className="anime-elem elem-2"
							alt="banner"
						/>
						<img
							src={anime_3}
							className="anime-elem elem-3"
							alt="banner"
						/>
						<img
							src={anime_4}
							className="anime-elem elem-4"
							alt="banner"
						/>
						<img
							src={anime_5}
							className="anime-elem elem-5"
							alt="banner"
						/>
					</div>
				</div>
				<div className="bg--base banner-bottom-divider"></div>
			</div>
		</section>
	);
};

export default Banner;
