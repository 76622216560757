import React from "react";

const Button = ({ button, className, text, ...rest }) => {
	if (button)
		return (
			<button className={`cmn--btn ${className ? className : ""}`} {...rest}>
				{text}
			</button>
		);
	return (
		<a className={`cmn--btn ${className ? className : ""}`} {...rest}>
			{text}
		</a>
	);
};

export default Button;
