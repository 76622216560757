import React from "react";
import { PopupButton } from '@typeform/embed-react'

const openNewWindow = () => {
  window.open('https://docs.google.com/spreadsheets/d/18lgKWMFv5Bas78_NndCT8M3L_KIl-s7ccur4HjrDWIc/edit#gid=788318036', '_blank');
}

const TypeFormButton = () => {
	return (
    <button onClick={openNewWindow} className={'cmn--btn'}>
      view more events
    </button>
	);
};

export default TypeFormButton;

/*
    <PopupButton id="QRT1vQ7d" className={'cmn--btn'}>
      view more events
    </PopupButton>
 */
