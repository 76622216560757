const footer_links = [
	{
		title: "SFBW 2018",
		link: "http://5.181.82.3/plesk-site-preview/2018.sfblockchainweek.io/https/10.4.18.3/",
	},
	{
		title: "SFBW 2019",
		link: "http://5.181.82.3/plesk-site-preview/2019.sfblockchainweek.io/https/10.4.18.3/",
	},
	{
		title: "SFBW 2020",
		link: "http://5.181.82.3/plesk-site-preview/sfblockchainweek.io/https/10.4.18.3/",
	},
	{
		title: "Contact Us",
		link: "mailto:info@sfblockchainweek.io",
	},
];

export default footer_links;