import img1 from "../images/event/event1.png";
import img2 from "../images/event/gam3r_forum.png";
import img3 from "../images/event/event3.png";
import img4 from "../images/event/event4.png";
import img5 from "../images/event/Spanaleonfx.svg";

const event = [
	{
		img: img1,
		title: "CESC",
		subtitle: "academic Conference",
		schedule: "October 31st - November 1st",
		btn__text: "Learn More",
		link: "http://cesc.io/",
	},
	{
		img: img2,
		title: "GAM3R Forum",
		subtitle: "Conference",
		schedule: "November 2nd",
		btn__text: "Learn More",
		link: "https://gam3r.org/",
	},
	{
		img: img3,
		title: "0xpo: Crossroads",
		subtitle: "Conference",
		schedule: "November 3rd",
		btn__text: "Learn More",
		link: "https://0xpo.com",
	},
];
export default event;
