import Banner from "./components/Banner/Banner";
import Cta from "./components/CTA/Cta";
import Event from "./components/Event/Event";
import EventInfo from "./components/Event/EventInfo";
import UpcomingEvents from "./components/Event/UpcomingEvents";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Organizers from "./components/Sponsors/Organizers";
import Supporters from "./components/Sponsors/Supporters";

function App() {
	return (
		<>
			<Header />
			<Banner />
			<EventInfo />
			<Event />
			<Cta />
			<Organizers />
			<UpcomingEvents />
			<Supporters />
			<Footer />
		</>
	);
}

export default App;
