import React from "react";
import footer__bottom__shape from "../../images/footer/footer-bottom-shape.png";
import FooterTop from "./FooterTop";
const Footer = () => {
	return (
		<footer id="bottom">
			<FooterTop />
			<div className="footer-bottom">
				<div className="footer-bottom-shape">
					<img src={footer__bottom__shape} alt="footer" />
				</div>
				<div className="container">
					<div className="footer-bottom-wrapper">
						<div className="copyright">
							Copyright © 2022. All Rights Reserved.
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
