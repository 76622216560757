// Supporters
import supporter1 from "../images/supporters/1.svg";
import supporter2 from "../images/supporters/2.svg";
import supporter3 from "../images/supporters/3.png";
import supporter4 from "../images/supporters/4.svg";
import supporter5 from "../images/supporters/5.svg";
import supporter6 from "../images/supporters/6.svg";
import supporter7 from "../images/supporters/7.svg";
import supporter8 from "../images/supporters/8.svg";
import supporter9 from "../images/supporters/9.png";
import supporter10 from "../images/supporters/10.png";
import supporter11 from "../images/supporters/11.png";
import supporter12 from "../images/supporters/12.svg";
import supporter13 from "../images/supporters/13.svg";
import supporter14 from "../images/supporters/14.svg";
import supporter15 from "../images/supporters/15.png";
import supporter16 from "../images/supporters/16.svg";
import supporter17 from "../images/supporters/17.svg";
import supporter18 from "../images/supporters/18.svg";
import supporter19 from "../images/supporters/19.svg";
import supporter20 from "../images/supporters/20.png";
import supporter21 from "../images/supporters/21.svg";
import supporter22 from "../images/supporters/22.png";
import supporter23 from "../images/supporters/23.svg";
import supporter24 from "../images/supporters/24.svg";
import supporter25 from "../images/supporters/25.svg";
import supporter26 from "../images/supporters/26.svg";
import supporter27 from "../images/supporters/27.svg";
import supporter28 from "../images/supporters/28.svg";
import supporter29 from "../images/supporters/29.png";
import supporter30 from "../images/supporters/30.png";
import supporter31 from "../images/supporters/31.svg";
import supporter32 from "../images/supporters/32.svg";
import supporter33 from "../images/supporters/33.svg";
import supporter34 from "../images/supporters/34.svg";
import supporter35 from "../images/supporters/35.png";
import supporter36 from "../images/supporters/36.png";
import supporter37 from "../images/supporters/37.svg";
import supporter38 from "../images/supporters/38.svg";
import supporter39 from "../images/supporters/39.svg";
import supporter40 from "../images/supporters/40.png";
import supporter41 from "../images/supporters/41.svg";
import supporter42 from "../images/supporters/42.svg";
import supporter43 from "../images/supporters/43.svg";
import supporter44 from "../images/supporters/44.svg";
import supporter45 from "../images/supporters/45.svg";
import supporter46 from "../images/supporters/46.svg";
import supporter47 from "../images/supporters/47.png";
import supporter48 from "../images/supporters/48.svg";
import supporter49 from "../images/supporters/49.svg";
import supporter50 from "../images/supporters/50.svg";
import supporter51 from "../images/supporters/51.svg";
import supporter52 from "../images/supporters/52.png";
import supporter53 from "../images/supporters/53.png";
import supporter54 from "../images/supporters/54.png";

import img1 from "../images/sponsor/1.png";
import img2 from "../images/sponsor/2.png";
import img3 from "../images/sponsor/3.png";
import img4 from "../images/sponsor/4.png";

// Co-organizers
import img5 from "../images/sponsor/5.png";
import img6 from "../images/sponsor/6.png";
import img7 from "../images/sponsor/7.png";
import img8 from "../images/sponsor/8.png";
import img9 from "../images/sponsor/9.png";
import img10 from "../images/sponsor/10.png";
import img11 from "../images/sponsor/11.png";


const supporters = [
	{
		img: supporter1
	},
	{
		img: supporter2
	},
	{
		img: supporter3
	},
	{
		img: supporter4
	},
	{
		img: supporter5
	},
	{
		img: supporter6
	},
	{
		img: supporter7
	},
	{
		img: supporter8
	},
	{
		img: supporter9
	},
	{
		img: supporter10
	},
	{
		img: supporter11
	},
	{
		img: supporter12
	},
	{
		img: supporter13
	},
	{
		img: supporter14
	},
	{
		img: supporter15
	},
	{
		img: supporter16
	},
	{
		img: supporter17
	},
	{
		img: supporter18
	},
	{
		img: supporter19
	},
	{
		img: supporter20
	},
	{
		img: supporter21
	},
	{
		img: supporter22
	},
	{
		img: supporter23
	},
	{
		img: supporter24
	},
	{
		img: supporter25
	},
	{
		img: supporter26
	},
	{
		img: supporter27
	},
	{
		img: supporter28
	},
	{
		img: supporter29
	},
	{
		img: supporter30
	},
	{
		img: supporter31
	},
	{
		img: supporter32
	},
	{
		img: supporter33
	},
	{
		img: supporter34
	},
	{
		img: supporter35
	},
	{
		img: supporter36
	},
	{
		img: supporter37
	},
	{
		img: supporter38
	},
	{
		img: supporter39
	},
	{
		img: supporter40
	},
	{
		img: supporter41
	},
	{
		img: supporter42
	},
	{
		img: supporter43
	},
	{
		img: supporter44
	},
	{
		img: supporter45
	},
	{
		img: supporter46
	},
	{
		img: supporter47
	},
	{
		img: supporter48
	},
	{
		img: supporter49
	},
	{
		img: supporter50
	},
	{
		img: supporter51
	},
	{
		img: supporter52
	},
	{
		img: supporter53
	},
	{
		img: supporter54
	},
]

const sponsors = [
	{
		img: img1,
	},
	{
		img: img2,
	},
	{
		img: img3,
	},
	{
		img: img4,
	},
];

const co_sponsors = [
	{
		img: img5,
	},
	{
		img: img6,
	},
	{
		img: img9,
	},
	{
		img: img7,
	},
	{
		img: img8,
	},
	{
		img: img10,
	},
	{
		img: img11,
	}
];

export { co_sponsors, sponsors, supporters };
