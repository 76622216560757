import React from "react";
import sponsor_bottom_shape from "../../images/sponsor/sponsor-bottom-shape.png";
import Button from "../Button/Button";
import Input from "../Input";

const UpcomingEvents = () => {
	return (
		<section className="upcoming-events-section">
			<img
				src={sponsor_bottom_shape}
				className="sponsor_bottom_shape d-none d-sm-block"
				alt="sponsor"
			/>
			<div className="container">
				<div className="header-content upcoming-events">
					<h2 className="title">Never miss any upcoming events.</h2>
					<p className="text--base">
						Sign up now to be the first to know about all the upcoming
						SFBW events, and more!
					</p>
				</div>
				<form action="https://sfblockchainweek.us18.list-manage.com/subscribe/post?u=ef521a4a33310d3367c256eb1&amp;id=a8fff664dc" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate className="validate input-wrapper">
					<Input required name="FNAME" id="mce-FNAME" type="text" placeholder="Enter Your Name" />
					<Input required name="EMAIL" id="mce-EMAIL" type="text" placeholder="Enter Your Email Address" />
					<div class="response" id="mce-error-response" style={{ display: "none" }}></div>
					<div class="response" id="mce-success-response" style={{ display: "none" }}></div>
					<Button
						button
						type="submit"
						className="bg--base text--base-2"
						text="I'M IN"
					/>
				</form>
			</div>
		</section>
	);
};

export default UpcomingEvents;
