import React from "react";
import { co_sponsors } from "../../data/sponsor";
const Organizers = () => {
	return (
		<section className="co-organizers-section">
			<div className="container">
				<div className="co-organizers">
					<div className="header-content">
						<h2 className="title">Co-Organizers</h2>
					</div>
					<div className="co-organizers-wrapper">
						{co_sponsors.length > 0 &&
							co_sponsors.map(({ img }, i) => (
								<div className="co-sponsor-img" key={i}>
									<img src={img} alt="sponsor" />
								</div>
							))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Organizers;
