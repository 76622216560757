import React from "react";
import event_info from "../../data/event__info";
import polygonshape from "../../images/event/icon/polygonshape.png";
const EventInfo = () => {
	return (
		<>
			<section className="event-info overflow-hidden position-relative">
				<div className="polygonshape-shape">
					<img src={polygonshape} alt="event" />
				</div>
				<div className="container">
					<div className="event-info-wrapper">
						{event_info.length > 0 &&
							event_info.map(({ img, title }, i) => (
								<div className="event-info-item" key={i}>
									<div className="event-icon">
										<img src={img} alt="event" />
									</div>
									<h4 className="subtitle">{title}</h4>
								</div>
							))}
					</div>
				</div>
			</section>
		</>
	);
};

export default EventInfo;
