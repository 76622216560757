import React from "react";
import { supporters } from "../../data/sponsor";
const Sponsors = () => {
	return (
		<section style={{
			background: '#AAB4D4',
		}} className="sponsor-section">
			<div className="container">
				<div className="co-organizers">
					<div className="header-content">
						<h2 className="title">Supporters</h2>
					</div>
					<div className="sponsor-wrapper">
						{supporters.length > 0 &&
							supporters.map(({ img }, i) => (
								<div className="sponsor-img" key={i}>
									<img src={img} alt="sponsor" />
								</div>
							))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Sponsors;
