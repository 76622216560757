import calendar from "../images/event/icon/calendar.png";
import map from "../images/event/icon/map-marker.png";
import speaker from "../images/event/icon/speaker.png";
const event_info = [
	{
		img: calendar,
		title: "Oct 31st to Nov 6th, 2022",
	},
	{
		img: map,
		title: "San Francisco and Berkeley",
	},
	{
		img: speaker,
		title: "300+ Speakers",
	},
];
export default event_info;
