import img1 from "../images/event/event1.png";
import img2 from "../images/event/gam3r_forum.png";
import img3 from "../images/event/event3.png";
import img4 from "../images/event/event4.png";
import img5 from "../images/event/Spanaleonfx.svg";

const event = [
	{
		img: img4,
		title: "ETH San Francisco",
		subtitle: "World's Biggest Ethereum Hackathon",
		schedule: "November 4th - November 6th",
		btn__text: "Learn More",
		link: "https://sf.ethglobal.com",
	},
	{
		img: img5,
		title: "Network Effects Hackathon",
		subtitle: "MultiChain & ZK Hackathon",
		schedule: "November 4th - November 6th",
		btn__text: "Learn More",
		link: "https://www.eventbrite.com/e/network-effects-hackathon-tickets-425847811267",
	},
];
export default event;
