import React from "react";
import telegramImg from "../../images/icons/telegram.svg";

const openNewWindow = () => {
  window.open('https://t.me/gmsfbw', '_blank');
}

const TypeFormButton = () => {
	return (
    <button onClick={openNewWindow} className={'cmn--btn-icon'}>
      <img src={telegramImg} className={'telegram-icon'} alt="event" />
      join community
    </button>
	);
};

export default TypeFormButton;

/*
    <PopupButton id="QRT1vQ7d" className={'cmn--btn'}>
      view more events
    </PopupButton>
 */
