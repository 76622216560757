import React, { useEffect, useState } from "react";
import event from "../../data/event.js";
import hackathon from "../../data/hackathon.js";
import event__bg__2 from "../../images/event/event-bg-2.png";
import event__bg from "../../images/event/event-bg.png";
import event__bottom from "../../images/event/event-bottom-img.png";
import Button from "../Button/Button.jsx";
const Event = () => {
	const [width, setWidth] = useState(window.innerWidth);

	const detectSize = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", detectSize);

		return () => {
			window.removeEventListener("resize", detectSize);
		};
	}, [width]);
	return (
		<section
			className="event-section bg_img"
			style={{
				backgroundImage: `url(${width >= 500 ? event__bg : event__bg__2})`,
			}}
		>
			<div className="container custom-container">
				<h2 className="section-title text-center text--base-2">
					Official Events
				</h2>
				<div className="row g-4 justify-content-center">
					{event.length > 0 &&
						event.map(
							(
								{ img, title, subtitle, schedule, btn__text, link },
								i
							) => (
								<div className="col-sm-6 col-lg-3" key={i}>
									<div className="event-item">
										<h3 className="event-name title text--base-2">{title}</h3>
										<img src={img} alt="event" />
										<h5 className="subtitle text--base-2">
											{subtitle}
										</h5>
										<h5 className="date text-white">{schedule}</h5>

										{link ? (
											<Button
												target="_blank"
												href={link}
												text={btn__text}
											/>
										) : (
											<Button text={btn__text} />
										)}
									</div>
								</div>
							)
						)}
				</div>
				<h2 className="section-title-2 text-center text--base-2">
					Hackathons
				</h2>
				<div className="row g-4 justify-content-center">
					{hackathon.length > 0 &&
						hackathon.map(
							(
								{ img, title, subtitle, schedule, btn__text, link },
								i
							) => (
								<div className="col-sm-6 col-lg-3" key={i}>
									<div className="event-item">
										<h3 className="event-name title text--base-2">{title}</h3>
										<img src={img} alt="event" />
										<h5 className="subtitle text--base-2">
											{subtitle}
										</h5>
										<h5 className="date text-white">{schedule}</h5>

										{link ? (
											<Button
												target="_blank"
												href={link}
												text={btn__text}
											/>
										) : (
											<Button text={btn__text} />
										)}
									</div>
								</div>
							)
						)}
				</div>
				<div className="event-bottom text-center text-white">
					<div className="text">
						SF Blockchain Week seeks to provide world-class education for
						both consumers and developers with the goal of pushing the
						boundaries of blockchain innovation. The focus of this
						week-long, immersive experience is to help the industry reach
						mainstream adoption through fundamental education and
						engagement with projects leading the way. There is truly
						something for everyone at this action-packed event. We ask
						that attendees follow two simple rules: Come to Learn and Stay
						Curious!
					</div>
					<img src={event__bottom} className="bottom-img" alt="event" />
				</div>
			</div>
		</section>
	);
};

export default Event;
