import React from "react";
import { BsTwitter } from "react-icons/bs";
import logo from "../../images/logo.png";
import Button from "../Button/Button";
const Header = () => {
	return (
		<header>
			<div className="container">
				<div className="header-wrapper">
					<div className="logo">
						<a href="">
							<img src={logo} alt="logo" />
						</a>
					</div>
					<div className="divider d-none d-sm-block"></div>
					<Button href="#bottom" text="Sign Up" className="btn-outline" />
					<a
						target="_blank"
						href="https://twitter.com/SFBWofficial?s=20&t=GXWReXqohjswqMJSUst0gQ"
						className="twitter-button"
						rel="noreferrer"
					>
						<BsTwitter />
						<span>follow us</span>
					</a>
				</div>
			</div>
		</header>
	);
};

export default Header;
