import React, { useEffect, useState } from "react";
import banner__bg_2 from "../../images/banner/banner-bg-2.png";
import banner__bg from "../../images/banner/banner-bg.png";
import banner from "../../images/banner/banner-img.png";

const BannerRight = () => {
	const [width, setWidth] = useState(window.innerWidth);

	const detectSize = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", detectSize);

		return () => {
			window.removeEventListener("resize", detectSize);
		};
	}, [width]);
	return (
		<div className="banner-thumb">
			<div className="bg-thumb">
				<img src={width >= 575 ? banner__bg : banner__bg_2} alt="banner" />
			</div>
			<div className="main-thumb">
				<img src={banner} alt="banner" />
			</div>
		</div>
	);
};

export default BannerRight;
