import React from "react";
import anime_1 from "../../images/cta/anime2.png";
import anime_2 from "../../images/cta/anime4.png";
import oval from "../../images/cta/cta-oval.png";
import cta_shape from "../../images/cta/cta-shape.png";
import TypeFormButton from "../Button/TypeFormButton";
import TelegramButton from "../Button/TelegramButton";
const Cta = () => {
	return (
		<section className="cta-section overflow-hidden position-relative">
			<div className="cta-oval">
				<img src={oval} alt="cta" />
			</div>
			<div className="cta-anime anime-1">
				<img src={anime_1} alt="cta" />
			</div>
			<div className="cta-anime anime-2">
				<img src={anime_2} alt="cta" />
			</div>
			<div className="cta-mobile-shape d-md-none">
				<img src={cta_shape} alt="cta" />
			</div>
			<div className="container">
				<div className="cta-content text-center">
					<h2 className="title">Wait, there's more!</h2>
					<p className="text--base">
						Explore all the blockchain - related events that are going on
						during the SFBW
					</p>
					<div className="cta-button-container">
						<TypeFormButton />
						<TelegramButton />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Cta;
