import React from "react";
import footer_links from "../../data/footer__links";
import footer_logo from "../../images/footer-logo.png";
import monile__shape from "../../images/footer/footer-mobile-shape.png";
import footer_oval from "../../images/footer/footer-oval.png";
import shape_2 from "../../images/footer/footer-shape2.png";

const FooterTop = () => {
	return (
		<div className="position-relative">
			<div className="footer-oval">
				<img src={footer_oval} alt="footer" />
			</div>
			<div className="footer-shape">
				<img src={shape_2} alt="footer" />
			</div>
			<div className="footer-mobile-shape d-sm-none">
				<img src={monile__shape} alt="footer" />
			</div>
			<div className="container">
				<div className="footer-top">
					<div className="footer-top-wrapper">
						<div className="footer-logo">
							<a href="">
								<img src={footer_logo} alt="images" />
							</a>
						</div>
						<div className="footer-menus">
							<ul>
								{footer_links.length > 0 &&
									footer_links.map(({ title, link }, i) => (
										<li key={i}>
											<a href={link}>{title}</a>
										</li>
									))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FooterTop;
